import React from 'react'
import Layout from "../components/layout";
import Seo from "../components/SEO/Seo"
import { useStaticQuery, graphql } from 'gatsby'
import '../styles/proyectos.scss'
import TarjetaProyecto from '../components/tarjetaProyecto'





export const query = graphql`
query {
    allStrapiProyecto (sort: {order: DESC, fields: published_at}) {
          edges {
              node {
                  titulo
                slug
                media_principal {
                      formats {
                          medium {
                              url
                          }
                      }
                }
              }
          }
    }
  }
  
`


export default function PaginaProyectos(props) {

  const data = useStaticQuery(query);

  return (
    <Layout>
      <Seo title="Proyectos" pathname={props.location.pathname} />
      <div className="primera-seccion container">
        <div className="contenedor-proyectos">
          {data.allStrapiProyecto.edges.map(({ node }, index) => {
            return <TarjetaProyecto clave={index} post={node} />
          })}
        </div>
      </div>
    </Layout>
  )
}
