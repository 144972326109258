import React from "react"
import { Link } from "gatsby"
import "../styles/tarjetaProyecto.scss"

export default function TarjetaProyecto(props) {
  let datos = props.post
  let key = props.clave + 1

  if (key < 10) {
    key = "0" + String(key)
  }

  return (
    <>
      <Link className="link-tarjeta" to={`/proyecto/${datos.slug}/`}>
        <article className="tarjeta-proyecto">
          <div className="col-imagen">
            <img
              src={`https://api.bullhost.es${datos.media_principal.formats.medium.url}`}
              alt=""
            />
            <span className="numpost">{key}</span>
            <h2>{datos.titulo}</h2>
          </div>
        </article>
      </Link>
    </>
  )
}
